const getOrdinalSuffix = (number: number) => {
  const ones = number % 10
  const tens = Math.floor(number / 10) % 10
  if (tens === 1) {
    return 'th'
  }
  switch (ones) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export default getOrdinalSuffix
