const getDisplayName = (fullName: string, middleName: string) => {
  let displayName: string

  const middleInitial = middleName ? middleName[0] + '.' : null
  fullName = fullName.split(',')[0]
  fullName = fullName.replace(/ *\([^)]*\) */g, ' ')
  fullName = fullName.replace('“', '"')
  fullName = fullName.replace('”', '"')

  const nickNameRegex = /(["'])(?:(?=(\\?))\2.)*?\1/g
  const nickNameArr = fullName.match(nickNameRegex)
  const nickName = nickNameArr ? nickNameArr[0] : null

  const newNameSplit = fullName.replace('  ', ' ').split(' ')

  if (nickName) {
    return fullName
  }

  switch (newNameSplit.length) {
    case 0:
      displayName = 'No Name'
      break
    case 1:
      displayName = fullName.replace(
        newNameSplit[0],
        `${newNameSplit[0]} ${middleInitial}`,
      )
      break
    case 2:
      displayName = fullName.replace(
        newNameSplit[1],
        `${middleInitial} ${newNameSplit[1]}`,
      )
      break
    case 3:
      displayName = middleInitial
        ? fullName.replace(newNameSplit[1], middleInitial)
        : fullName.replace(newNameSplit[1], `${newNameSplit[1][0]}.`)
      break
    default:
      displayName = fullName
      break
  }

  return displayName
}

export default getDisplayName
