import React, { useEffect, useRef, useState } from 'react'
import Select, {
  components,
  OptionProps,
  GroupBase,
  SelectInstance,
} from 'react-select'
import { useAppDispatch } from '../redux/hooks'
import { setBranch } from '../redux/slices/filterSlice'
import mapColor from '../utils/mapColor'
import '../App.css'
const PeopleSelect = (props: PeopleSelect) => {
  const { branches, filterMenu = false } = props

  const [selectedOption, setSelectedOption] = useState<string>('')
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [hoveredOption, setHoveredOption] = useState<string | null>(null)

  const dispatch = useAppDispatch()

  const selectRef = useRef<SelectInstance<
    { value: string; label: JSX.Element },
    boolean,
    GroupBase<{ value: string; label: JSX.Element }>
  > | null>(null)

  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const Option = (
    _props: OptionProps<
      { value: string; label: JSX.Element },
      boolean,
      GroupBase<{ value: string; label: JSX.Element }>
    >,
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let value = _props.value

    return (
      <components.Option {..._props}>
        <div
          className={'people-option'}
          onClick={e => (selectedOption === value ? e.stopPropagation() : null)}
          onMouseEnter={() => setHoveredOption(value)}
          onMouseLeave={() => setHoveredOption(null)}
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            padding: 10,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 5,
          }}>
          <div>{_props.label}</div>
        </div>
      </components.Option>
    )
  }

  useEffect(() => {
    dispatch(setBranch(selectedOption))
  }, [selectedOption])

  const options = branches
    .sort((a, b) => (a.order < b.order ? -1 : 1))
    .map((branch, index) => {
      return {
        value: branch.name,
        label: (
          <div key={index} style={styles.labelContainer}>
            <div
              style={{
                ...styles.optionImageWrapper,
                borderColor: mapColor(branch.color),
                overflow:
                  selectedOption === branch.name ||
                  hoveredOption === branch.name
                    ? 'visible'
                    : 'hidden',
              }}>
              <img
                className={
                  selectedOption === branch.name
                    ? 'option-image-selected'
                    : 'option-image'
                }
                style={{
                  ...styles.optionImage,
                  filter:
                    selectedOption === branch.name ||
                    hoveredOption === branch.name
                      ? 'none'
                      : 'grayscale(100%)',
                }}
                src={branch.icon}
                alt={branch.shortName}
              />
            </div>
            <div
              style={{ ...styles.optionText, color: mapColor(branch.color) }}>
              {branch.shortName}
            </div>
            {selectedOption === branch.name ? (
              <img
                alt={'x'}
                src={require('../assets/XIcon.png')}
                onClick={e => {
                  e.stopPropagation()
                  selectRef.current?.clearValue()
                }}
                style={styles.xImage}
              />
            ) : null}
          </div>
        ),
      }
    })

  const handleClick = (e: MouseEvent) => {
    if (!wrapperRef.current?.contains(e.target as Node)) {
      setMenuIsOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [])

  return (
    <div ref={wrapperRef} style={styles.selectContainer}>
      {!filterMenu && <div style={styles.selectText}>Branch:</div>}
      <div
        style={{
          ...styles.dropdownWrapper,
          borderColor: menuIsOpen ? '#3B2A2D' : '#D8DAE0',
        }}>
        <Select
          ref={selectRef}
          onMenuOpen={() => setMenuIsOpen(true)}
          onMenuClose={() => setMenuIsOpen(false)}
          menuIsOpen={menuIsOpen}
          isSearchable={false}
          isMulti={false}
          placeholder={'All'}
          onChange={option => {
            const o = option as unknown as {
              value: string
              label: JSX.Element
            } | null
            setSelectedOption(o?.value || '')
          }}
          options={options}
          styles={styles.customSelectStyles}
          components={{ Option }}
        />
      </div>
    </div>
  )
}

const styles = {
  dropdownWrapper: {
    borderStyle: 'solid',
    borderWidth: 0,
    borderBottomWidth: 1,
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
  } as React.CSSProperties,
  selectText: {
    marginRight: 20,
    marginTop: 10,
    color: '#3B2A2D',
    fontSize: 15,
    fontWeight: 600,
  } as React.CSSProperties,
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 1,
  } as React.CSSProperties,
  optionText: {
    marginLeft: 10,
    marginTop: 5,
    fontSize: 18,
    fontWeight: 600,
  } as React.CSSProperties,
  optionImageWrapper: {
    height: 30,
    width: 30,
    borderRadius: '50%',
    borderWidth: 2,
    borderStyle: 'solid',
  } as React.CSSProperties,
  optionImage: {
    height: '100%',
    width: '100%',
    borderRadius: '50%',
  } as React.CSSProperties,
  xImage: {
    position: 'absolute',
    width: 15,
    height: 15,
    right: 15,
    top: '50%',
    transform: 'translateY(-50%)',
    color: '#3B2A2D',
  } as React.CSSProperties,
  customSelectStyles: {
    container: (provided: object) => ({
      ...provided,
      cursor: 'pointer',
      width: 220,
    }),
    placeHolderText: (provided: object) => ({
      ...provided,
      font: 'Ideal Sans',
      fontWeight: 400,
      color: '#3B2A2D',
      fontSize: 18,
    }),
    menu: (provided: object) => ({
      ...provided,
      padding: 0,
      marginTop: 2,
      paddingTop: 10,
      paddingBottom: 5,
      maxHeight: 'none',
      width: 'fit-content',
      borderRadius: 0,
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
    }),
    menuList: (provided: object) => ({
      ...provided,
      padding: 0,
      paddingLeft: 10,
      paddingRight: 10,
      margin: 0,
      maxHeight: 'none',
    }),
    option: (provided: object, state: { isSelected: boolean }) => ({
      ...provided,
      width: 200,
      overflow: 'visible',
      backgroundColor: state.isSelected ? '#E8E8E8' : 'white',
      borderRadius: 6,
      color: 'black',
      padding: 0,
      margin: 0,
      cursor: 'pointer',
      '&:hover': {
        dropShadow: 5.0,
        backgroundColor: '#E8E8E8',
      },
    }),
    indicatorSeparator: (provided: object) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (provided: object) => ({
      ...provided,
      color: '#3B2A2D',
    }),
    control: (provided: object, state: { menuIsOpen: boolean }) => ({
      ...provided,
      border: 'none',
      borderRadius: 0,
      borderStyle: 'solid',
      borderWidth: 0,
      borderBottomWidth: 1,
      borderColor: state.menuIsOpen ? '#3B2A2D' : 'blue',
      minWidth: 170,
      boxShadow: 'none',
      paddingBottom: 5,
      cursor: 'pointer',
    }),
    placeholder: (provided: object) => ({
      ...provided,
      color: '#3B2A2D',
      display: 'All',
    }),
  },
}

export default PeopleSelect
