import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

export const initialState: ModalSliceState = {
  visible: false,
}
const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setVisible: (state, action) => {
      state.visible = action.payload
    },
  },
})

export const selectModal = (state: RootState) => state.modalSlice
export const { setVisible } = modalSlice.actions
export default modalSlice.reducer
