import React, { useEffect } from 'react'
import axios from 'axios'
import Loading from './components/Loading'

const Auth = () => {
  const authenticate = async () => {
    const res = await axios.post(
      process.env.REACT_APP_API_BASE_URL +
        '/authenticate-rhQBtwzpQVZsipg3?token=dm9pY2UtbWFzdGVyOkN6UWFHVzlyaTI3XyE3UFByYg==',
    )
    return res
  }
  useEffect(() => {
    authenticate().then(res => {
      const data = res.data
      const cookies = data as {
        id: string
        secret: string
      }

      //const url = window.localStorage.getItem('treeRedirectUrl')

      if (cookies) {
        const now = new Date()
        const time = now.getTime()
        const expireTime = time + 1000 * 36000
        now.setTime(expireTime)
        document.cookie = `tree-client-id=${
          cookies.id
        }; SameSite=None; Secure;${
          process.env.REACT_APP_STAGE === 'prod'
            ? 'Domain=www.barnardfamilyconnect.com;'
            : ''
        }Expires=${now.toUTCString()}`
        document.cookie = `tree-client-secret=${
          cookies.secret
        }; SameSite=None; Secure;${
          process.env.REACT_APP_STAGE === 'prod'
            ? 'Domain=www.barnardfamilyconnect.com;'
            : ''
        }Expires=${now.toUTCString()}`
      }

      if (top) {
        top.window.location.href =
          process.env.REACT_APP_SITE_BASE_URL ||
          'https://www.barnardfamilyconnect.com'
      }
    })
  }, [])

  return <Loading text={'Signing in...'} />
}

export default Auth
