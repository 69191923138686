import React from 'react'
import ProfileImage from '../ProfileImage'
import './index.css'
import getDisplayName from '../../utils/getDisplayName'
import mapColor from '../../utils/mapColor'

const PersonNode = (props: PersonNode) => {
  const {
    data,
    fullName,
    middleName,
    avatarUrl,
    branch,
    dateOfBirth,
    dateOfDeath,
    directDescendant,
    branches = [],
    onClick,
  } = props

  let date, birth, death

  if (dateOfBirth) {
    birth = new Date(dateOfBirth).getFullYear()
  }
  if (dateOfDeath) {
    death = new Date(dateOfDeath).getFullYear()
  }

  if (birth && death) {
    date = `${birth}-${death}`
  } else if (birth) {
    date = `b. ${birth}`
  }

  const branchColor = branches.find(b => b.name === branch)?.color || 'gray'

  return (
    <div
      className="container"
      style={styles.container}
      onClick={() => onClick(data)}>
      <div
        style={{
          ...styles.border,
          backgroundColor: directDescendant
            ? mapColor(branchColor)
            : mapColor(branchColor) + 'B2',
        }}>
        <ProfileImage
          image={avatarUrl ? avatarUrl : undefined}
          size={56}
          showBorder={true}
        />
      </div>
      <div style={styles.textWrapper}>
        <div className="name" style={styles.name}>
          {middleName ? getDisplayName(fullName, middleName) : fullName}
        </div>
        {date ? <div style={styles.date}>{date}</div> : null}
      </div>
    </div>
  )
}

const styles = {
  container: {
    width: 285,
    height: 104,
    background: '#FFFFFF',
    boxShadow: '0px 3px 6px #C6C9CE29',
    border: '1px solid #D8DAE0',
    borderRadius: 6,
    position: 'relative',
    cursor: 'pointer',
    transition: 'all 0.5s ease',
  } as React.CSSProperties,
  border: {
    width: 285,
    height: 6,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    marginTop: -2,
    marginLeft: -1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  } as React.CSSProperties,
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    marginTop: 8,
  } as React.CSSProperties,
  icon: {
    height: 18,
    width: 18,
    marginTop: 3,
    marginRight: 12,
  } as React.CSSProperties,
  name: {
    fontWeight: 600,
    textAlign: 'center',
    fontSize: 18,
    fontFamily: 'IdealSans',
    marginBottom: 5,
    color: '#3B2A2D',
  } as React.CSSProperties,
  date: {
    color: '#94888A',
    fontSize: 18,
    fontStyle: 'italic',
    fontFamily: 'IdealSans',
    fontWeight: 300,
  } as React.CSSProperties,
}

export default PersonNode
