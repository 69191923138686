import React, { useState } from 'react'
import ProfileImage from './ProfileImage'
import { DEFAULT_PROFILE_IMAGE_URL } from '../globals'
import { useNavigate } from 'react-router-dom'

const CardPeopleList = (props: CardPeopleList) => {
  const { people, minimized } = props

  const [hoveredGroupIndex, setHoveredGroupIndex] = useState<number | null>(
    null,
  )
  const [hoveredPersonIndex, setHoveredPersonIndex] = useState<number | null>(
    null,
  )

  const navigate = useNavigate()

  return (
    <div
      style={{
        ...styles.container,
        width: minimized ? 'auto' : 275,
        overflowY: minimized ? 'auto' : 'scroll',
        borderTopRightRadius: minimized ? 7 : 0,
        borderBottomRightRadius: minimized ? 7 : 0,
      }}>
      <div style={styles.subContainer}>
        {!minimized && (
          <div
            style={{
              backgroundColor: '#e8e8e8',
              width: 255,
              height: 35,
              position: 'fixed',
            }}
            onClick={() => {
              navigate('/')
            }}>
            <img
              alt={'close'}
              src={require('../assets/Close.png')}
              style={styles.closeIcon}
            />
          </div>
        )}
        <div
          style={{ ...styles.peopleWrapper, paddingTop: minimized ? 30 : 50 }}>
          {people.map((group, index) => {
            return (
              <div key={index} style={styles.peopleGroup}>
                <div style={styles.groupHeading}>{group.groupHeading}</div>
                {group.people.map((person, _index) => {
                  return (
                    <div
                      key={_index}
                      onClick={() => {
                        navigate(`/person/${person.id}`)
                      }}
                      onMouseEnter={() => {
                        setHoveredGroupIndex(index)
                        setHoveredPersonIndex(_index)
                      }}
                      onMouseLeave={() => {
                        setHoveredGroupIndex(null)
                        setHoveredPersonIndex(null)
                      }}
                      style={{
                        ...styles.personWrapper,
                        textDecoration:
                          hoveredGroupIndex === index &&
                          hoveredPersonIndex === _index
                            ? 'underline'
                            : 'none',
                      }}>
                      <ProfileImage
                        image={person.avatarUrl || DEFAULT_PROFILE_IMAGE_URL}
                        size={25}
                        showBorder={false}
                      />
                      <div style={styles.personName}>{person.fullName}</div>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const styles = {
  container: {
    position: 'relative',
    height: '100%',
    overflowY: 'scroll',
    borderTopLeftRadius: 7,
    borderBottomLeftRadius: 7,
  } as React.CSSProperties,
  subContainer: {
    position: 'relative',
    height: '100%',
    backgroundColor: '#e8e8e8',
  } as React.CSSProperties,
  peopleWrapper: {
    paddingTop: 50,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
    display: 'flex',
    flexDirection: 'column',
  } as React.CSSProperties,
  peopleGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
  } as React.CSSProperties,
  groupHeading: {
    color: '#ADA0A2',
    fontSize: 14,
    letterSpacing: 1.4,
    textTransform: 'uppercase',
    fontWeight: 600,
    marginBottom: 5,
  } as React.CSSProperties,
  personWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
    cursor: 'pointer',
  } as React.CSSProperties,
  personImage: {} as React.CSSProperties,
  personName: {
    color: '#3B2A2D',
    fontSize: 16,
    fontWeight: 500,
    marginTop: 5,
    marginLeft: 8,
  } as React.CSSProperties,
  closeIcon: {
    position: 'fixed',
    top: 10,
    left: -265,
    width: 15,
    height: 15,
    cursor: 'pointer',
  } as React.CSSProperties,
}

export default CardPeopleList
