import React from 'react'
import { DEFAULT_PROFILE_IMAGE_URL } from '../globals'

const ProfileImage = (props: ProfileImage) => {
  const {
    image = DEFAULT_PROFILE_IMAGE_URL,
    size,
    showBorder,
    borderColor = 'white',
  } = props
  return (
    <div
      className={'profile-image'}
      style={{
        ...styles.container,
        width: size,
        height: size,
        borderWidth: showBorder ? 5 : 0,
        borderColor,
        backgroundColor: borderColor,
      }}>
      <img src={image} alt={image} style={styles.image} />
    </div>
  )
}

const styles = {
  container: {
    borderRadius: '50%',
    borderStyle: 'solid',
    overflow: 'hidden',
  } as React.CSSProperties,
  image: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  } as React.CSSProperties,
}

export default ProfileImage
