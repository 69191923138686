import React, { useState } from 'react'

const PersonContactDetails = (props: PersonContactDetails) => {
  const { phone, address, occupation, email, minimized } = props

  const [phoneHovered, setPhoneHovered] = useState(false)
  const [emailHovered, setEmailHovered] = useState(false)
  const [addressHovered, setAddressHovered] = useState(false)

  const occupationTruncated = occupation
    ? occupation.length > 75
      ? occupation.substring(0, 75) + '...'
      : occupation
    : ''

  // const skillsTruncated = skills
  //   ? skills.length > 75
  //     ? skills.substring(0, 75) + '...'
  //     : skills
  //   : ''

  return (
    <div
      style={{
        ...styles.container,
        flexDirection: minimized ? 'column' : 'row',
      }}>
      <div style={styles.leftColumn}>
        <div
          style={{
            ...styles.header,
            marginBottom: 12,
            textAlign: minimized ? 'center' : 'left',
          }}>
          Contact
        </div>
        <div style={styles.leftColumnRow}>
          <img
            alt={'phone'}
            src={require('../assets/Phone.png')}
            style={styles.icon}
          />
          <a
            onMouseEnter={() => setPhoneHovered(true)}
            onMouseLeave={() => setPhoneHovered(false)}
            style={{
              ...styles.text,
              textDecoration: phone && phoneHovered ? 'underline' : 'none',
            }}
            href={phone ? `tel:${phone?.replace(/\D/g, '')}` : ''}>
            {phone || 'N/A'}
          </a>
        </div>
        <div style={styles.leftColumnRow}>
          <img
            alt={'email'}
            src={require('../assets/Email.png')}
            style={styles.icon}
          />
          <a
            onMouseEnter={() => setEmailHovered(true)}
            onMouseLeave={() => setEmailHovered(false)}
            style={{
              ...styles.text,
              textDecoration: email && emailHovered ? 'underline' : 'none',
            }}
            href={email ? `mailto:${email}` : ''}>
            {email || 'N/A'}
          </a>
        </div>
        <div style={{ ...styles.leftColumnRow, marginBottom: 0 }}>
          <img
            alt={'address'}
            src={require('../assets/Address.png')}
            style={styles.icon}
          />
          <a
            onMouseEnter={() => setAddressHovered(true)}
            onMouseLeave={() => setAddressHovered(false)}
            style={{
              ...styles.text,
              textDecoration: addressHovered ? 'underline' : 'none',
              minHeight: 45,
            }}
            href={
              address
                ? `https://www.google.com/maps/place/${address?.replace(
                    / /g,
                    '+',
                  )}`
                : ''
            }
            target="__blank">
            {address || 'N/A'}
          </a>
        </div>
      </div>
      <div style={{ ...styles.rightColumn, marginTop: minimized ? 35 : 0 }}>
        <div style={styles.rightColumnRow}>
          <div
            style={{
              ...styles.header,
              marginBottom: 14,
              textAlign: minimized ? 'center' : 'left',
            }}>
            Occupation
          </div>
          <div style={styles.text}>{occupationTruncated || 'N/A'}</div>
        </div>
        {/*<div>*/}
        {/*  <div*/}
        {/*    style={{*/}
        {/*      ...styles.header,*/}
        {/*      marginBottom: 14,*/}
        {/*      textAlign: minimized ? 'center' : 'left',*/}
        {/*    }}>*/}
        {/*    Skills/Hobbies*/}
        {/*  </div>*/}
        {/*  <div style={styles.text}>{skillsTruncated || 'N/A'}</div>*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
  } as React.CSSProperties,
  leftColumn: {
    width: 250,
  } as React.CSSProperties,
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  } as React.CSSProperties,
  header: {
    color: '#ADA0A2',
    fontSize: 14,
    letterSpacing: 1.4,
    textTransform: 'uppercase',
    fontWeight: 600,
  } as React.CSSProperties,
  leftColumnRow: {
    display: 'flex',
    marginBottom: 22,
  },
  text: {
    color: '#3B2A2D',
    fontSize: 18,
    fontWeight: 300,
    maxWidth: 200,
  } as React.CSSProperties,
  rightColumnRow: {
    marginBottom: 25,
  } as React.CSSProperties,
  icon: {
    height: 18,
    width: 18,
    marginTop: 3,
    marginRight: 12,
  } as React.CSSProperties,
}

export default PersonContactDetails
