import React from 'react'
import moment from 'moment'

const NameDateRange = (props: NameDateRange) => {
  const { name, birthDate, deathDate, minimized } = props

  const birthDateFormatted = birthDate
    ? moment(birthDate).format('MMMM D, YYYY')
    : null
  const deathDateFormatted = deathDate
    ? moment(deathDate).format('MMMM D, YYYY')
    : 'Present'

  return (
    <div
      style={{
        ...styles.container,
        alignItems: minimized ? 'center' : 'left',
      }}>
      <div style={styles.nameWrapper}>{name}</div>
      <div style={styles.dateRangeWrapper}>
        {birthDateFormatted
          ? birthDateFormatted + ' - ' + deathDateFormatted
          : ''}
      </div>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  } as React.CSSProperties,
  nameWrapper: {
    color: '#3B2A2D',
    fontSize: 24,
    fontWeight: 600,
  } as React.CSSProperties,
  dateRangeWrapper: {
    color: '#94888A',
    fontSize: 18,
    fontStyle: 'italic',
  } as React.CSSProperties,
}

export default NameDateRange
