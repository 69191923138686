import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { REHYDRATE } from 'redux-persist'
import axios, { AxiosResponse } from 'axios'

export const familyTreeApi = createApi({
  reducerPath: 'familyTreeApi',
  refetchOnMountOrArgChange: true,
  extractRehydrationInfo: (action, { reducerPath }) => {
    if (action.type === REHYDRATE && action.payload) {
      return action.payload[reducerPath]
    }
  },
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': 'true',
    },
  }),
  endpoints: builder => ({
    getPeopleData: builder.query<ApiResponseResult[], void>({
      // query: ({ ...params }) => `people?next_cursor=${params.next_cursor}`,
      queryFn: async () => {
        try {
          const data: ApiResponseResult[] = []
          let response: AxiosResponse<ApiResponse> | null = null
          do {
            response = await axios.get(
              `${process.env.REACT_APP_API_BASE_URL}/people?next_cursor=${
                response ? response?.data.next_cursor || '' : ''
              }`,
              {
                withCredentials: true,
              },
            )
            if (response) {
              data.push(...response.data.results)
            }
          } while (response?.data.has_more)
          return {
            data,
          }
        } catch (e) {
          return {
            error: e as FetchBaseQueryError,
          }
        }
      },
      extraOptions: {
        refetchOnMountOrArgChange: true,
      },
    }),
    getFamilyData: builder.query<ApiResponseResult[], void>({
      queryFn: async () => {
        try {
          const data: ApiResponseResult[] = []
          let response: AxiosResponse<ApiResponse> | null = null
          do {
            response = await axios.get(
              `${process.env.REACT_APP_API_BASE_URL}/families?next_cursor=${
                response ? response?.data.next_cursor || '' : ''
              }`,
              {
                withCredentials: true,
              },
            )
            if (response) {
              data.push(...response.data.results)
            }
          } while (response?.data.has_more)
          return {
            data,
          }
        } catch (e) {
          return {
            error: e as FetchBaseQueryError,
          }
        }
      },
      extraOptions: {
        refetchOnMountOrArgChange: true,
      },
    }),
    getBranchData: builder.query<ApiResponseResult[], void>({
      queryFn: async () => {
        try {
          const data: ApiResponseResult[] = []
          let response: AxiosResponse<ApiResponse> | null = null
          do {
            response = await axios.get(
              `${process.env.REACT_APP_API_BASE_URL}/branches?next_cursor=${
                response ? response?.data.next_cursor || '' : ''
              }`,
              {
                withCredentials: true,
              },
            )
            if (response) {
              data.push(...response.data.results)
            }
          } while (response?.data.has_more)
          return {
            data,
          }
        } catch (e) {
          return {
            error: e as FetchBaseQueryError,
          }
        }
      },
      extraOptions: {
        refetchOnMountOrArgChange: true,
      },
    }),
    getResidenceData: builder.query<ApiResponseResult[], void>({
      queryFn: async () => {
        try {
          const data: ApiResponseResult[] = []
          let response: AxiosResponse<ApiResponse> | null = null
          do {
            response = await axios.get(
              `${process.env.REACT_APP_API_BASE_URL}/residences?next_cursor=${
                response ? response?.data.next_cursor || '' : ''
              }`,
              {
                withCredentials: true,
              },
            )
            if (response) {
              data.push(...response.data.results)
            }
          } while (response?.data.has_more)
          return {
            data,
          }
        } catch (e) {
          return {
            error: e as FetchBaseQueryError,
          }
        }
      },
      extraOptions: {
        refetchOnMountOrArgChange: true,
      },
    }),
    getColorData: builder.query<ApiResponseResult[], void>({
      queryFn: async () => {
        try {
          const data: ApiResponseResult[] = []
          let response: AxiosResponse<ApiResponse> | null = null
          do {
            response = await axios.get(
              `${process.env.REACT_APP_API_BASE_URL}/colors?next_cursor=${
                response ? response?.data.next_cursor || '' : ''
              }`,
              {
                withCredentials: true,
              },
            )
            if (response) {
              data.push(...response.data.results)
            }
          } while (response?.data.has_more)
          return {
            data,
          }
        } catch (e) {
          return {
            error: e as FetchBaseQueryError,
          }
        }
      },
      extraOptions: {
        refetchOnMountOrArgChange: true,
      },
    }),
  }),
})

export const {
  useGetPeopleDataQuery,
  useGetFamilyDataQuery,
  useGetBranchDataQuery,
  useGetResidenceDataQuery,
  useGetColorDataQuery,
} = familyTreeApi
