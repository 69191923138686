import React, { useEffect, useState } from 'react'
import CardDetailsSectionMobile from '../components/CardDetailsSectionMobile'
import CardDetailsSection from '../components/CardDetailsSection'
import { useLocation, useNavigate } from 'react-router-dom'
import addRelevantPeopleDataToPerson from '../utils/addRelevantPeopleDataToPerson'
import { useWindowSize } from '../hooks/useWindowSize'

const PersonInfoModal = (props: PersonInfoModal) => {
  const { people, families } = props

  const [mobile, setMobile] = useState(false)
  const [minimized, setMinimized] = useState(false)

  const location = useLocation()
  const navigate = useNavigate()

  const { width } = useWindowSize()

  const [person, setPerson] = React.useState<Person | null>(null)

  const [visible, setVisible] = React.useState(false)

  useEffect(() => {
    const personId = location.pathname.split('/')[2]
    const personWithId: Person | undefined = people.find(
      (_person: Person) => _person.id === personId,
    )
    if (personWithId) {
      setPerson(addRelevantPeopleDataToPerson(personWithId, people, families))
      setVisible(true)
    } else {
      setPerson(null)
      setVisible(false)
    }
  }, [location.pathname])

  useEffect(() => {
    if (width > 585 && mobile) {
      setMobile(false)
    }

    if (width <= 585 && !mobile) {
      setMobile(true)
    }

    if (width <= 855 && !minimized) {
      setMinimized(true)
    }

    if (width > 855 && minimized) {
      setMinimized(false)
    }
  }, [width])

  return !visible || !person ? null : (
    <div style={styles.container}>
      <div
        onClick={e => {
          e.stopPropagation()
          navigate('/')
        }}
        style={styles.backdrop}></div>
      <div
        style={{
          ...styles.card,
          transform: minimized ? 'none' : 'translateX(137px)',
        }}>
        {!mobile && (
          <CardDetailsSection person={person} minimized={minimized} />
        )}
        {mobile && <CardDetailsSectionMobile person={person} />}
      </div>
    </div>
  )
}

const styles = {
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  } as React.CSSProperties,
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  } as React.CSSProperties,
  card: {
    zIndex: 10,
    display: 'flex',
    boxShadow: '0px 4px 4px #C6C9CE29',
  } as React.CSSProperties,
}

export default PersonInfoModal
