const addRelevantPeopleDataToPerson = (
  person: Person,
  people: Person[],
  families: Family[],
) => {
  const parents: Person[] = []
  let spouse: Person | null | undefined = null
  const children: Person[] = []
  const relevantPeople: RelevantPeopleGroup[] = []

  const parentsFamilies: Family[] = families.filter(
    family => family.id === person.parents,
  )

  const parentsFamily: Family | null =
    parentsFamilies.length > 0 ? parentsFamilies[0] : null

  if (parentsFamily) {
    parentsFamily.partners.forEach(partnerId => {
      const partner = people.find(_person => _person.id === partnerId)
      if (partner) {
        parents.push(partner)
      }
    })
  }

  const familiesWithPerson = families.filter(family =>
    person.families.includes(family.id),
  )

  if (familiesWithPerson.length > 0) {
    const currentFamilies = familiesWithPerson.filter(
      family => family.currentRelationship,
    )
    if (currentFamilies.length > 0) {
      const currentFamily = currentFamilies[0]
      const spouseId = currentFamily.partners.find(
        (partnerId: string) => partnerId !== person.id,
      )
      if (spouseId) {
        spouse = people.find((_person: Person) => _person.id === spouseId)
      }
    }

    const childrenIds = familiesWithPerson
      .map(family => family?.children)
      .flat()
    childrenIds.forEach((childId: string) => {
      const child = people.find((_person: Person) => _person.id === childId)
      if (child) {
        children?.push(child)
      }
    })
  }

  if (parents.length > 0) {
    relevantPeople.push({
      groupHeading: 'Parents',
      people: parents,
    })
  }

  if (spouse) {
    relevantPeople.push({
      groupHeading: 'Spouse',
      people: [spouse],
    })
  }

  if (children && children.length > 0) {
    relevantPeople.push({
      groupHeading: 'Children',
      people: children,
    })
  }

  return {
    ...person,
    relevantPeople,
  }
}

export default addRelevantPeopleDataToPerson
