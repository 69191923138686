const mapColor = (color: string) => {
  switch (color) {
    case 'Yellow':
      return '#F4C36E'
    case 'Orange':
      return '#E28E63'
    case 'Red':
      return '#E66958'
    case 'Purple':
      return '#9173AF'
    case 'Blue':
      return '#50A4C7'
    case 'Green':
      return '#40947A'
    default:
      return color.toLowerCase()
  }
}

export default mapColor
