import React from 'react'
import useHover from '../hooks/useHover'

const Button = (props: Button) => {
  const { type = 'primary', text, onClick, width } = props
  const [hoverRef, isHovered] = useHover<HTMLDivElement>()

  const backgroundColor = type === 'primary' ? '#B4CCC2' : 'white'
  const backgroundColorHovered =
    type === 'primary' ? '#A6BFB5' : 'rgba(148,136,138, 0.1)'

  const color = '#3B2A2D'
  const colorHovered = color

  const borderColor = type === 'primary' ? '#B4CCC2' : '#3B2A2D'
  const borderColorHovered = type === 'primary' ? '#CEE0DA' : '#3B2A2D'

  return (
    <div
      ref={hoverRef}
      style={{
        ...styles.container,
        color: isHovered ? colorHovered : color,
        backgroundColor: isHovered ? backgroundColorHovered : backgroundColor,
        borderColor: isHovered ? borderColorHovered : borderColor,
        width: width || 'fit-content',
      }}
      onClick={onClick}>
      {text}
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 15,
    fontWeight: 600,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 6,
    borderStyle: 'solid',
    borderWidth: 1,
    width: 'fit-content',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',
  } as React.CSSProperties,
}

export default Button
