export type ApiResponsePerson = Person & {
  inheritedBranch?: string | null
  familyBranch?: string | null
  parentBranch?: string | null
  branchStart?: string | null
}

export type ApiResponseBranch = Branch & {
  id?: string
  origin?: string
}
const createTreeData = (
  peopleRes: ApiResponseResult[],
  familiesRes: ApiResponseResult[],
  branchesRes: ApiResponseResult[],
  residenceRes: ApiResponseResult[],
  colorsRes: ApiResponseResult[],
): Tree => {
  const people: ApiResponsePerson[] = []
  const families: Family[] = []
  const branches: ApiResponseBranch[] = []

  branchesRes.forEach(branch => {
    const color = colorsRes.find(c => c.id === branch.properties.colorId)
    const colorName = color?.properties.name
    branches.push({
      id: branch.id,
      name: branch.properties.name || '',
      shortName: branch.properties.shortName || '',
      color: colorName || '',
      origin: branch.properties.origin || '',
      icon: branch.icon,
      order: branch.properties.order || 0,
    })
  })

  familiesRes.forEach(family => {
    families.push({
      id: family.id,
      partners: family.properties.partners || ['', ''],
      children: family.properties.children || [],
      marriageDate: family.properties.marriageDate || null,
      currentRelationship: family.properties.currentRelationship || false,
      generation: family.properties.generation || -1,
      branch: family.properties.branch || null,
    })
  })

  peopleRes.forEach(person => {
    const secondaryResidenceId = person.properties.secondaryResidenceId
    let secondaryResidenceAddress: string | null = null
    if (secondaryResidenceId) {
      const secondaryResidence = residenceRes.find(
        residence => residence.id === secondaryResidenceId,
      )
      if (secondaryResidence) {
        secondaryResidenceAddress =
          secondaryResidence.properties.address || null
      }
    }

    let generation = person.properties.generation || -1
    if (!person.properties.generation) {
      const familyGens = families
        .filter(f => person.properties.familyIds?.includes(f.id))
        ?.map(f => f.generation)
      const familyGen = Math.max(...familyGens)

      if (familyGen > 0) {
        generation = familyGen
      } else {
        const parentsGen = families.filter(
          f => f.id === person.properties.parentsId,
        )[0]?.generation
        if (parentsGen) {
          generation = parentsGen + 1
        }
      }
    }

    people.push({
      id: person.id,
      fullName: person.properties.fullName || '',
      middleName: person.properties.middleName || '',
      email: person.properties.email || '',
      cellPhone: person.properties.cellPhone || '',
      homePhone: person.properties.homePhone || '',
      primaryHomeAddress: person.properties.primaryHomeAddress || '',
      secondaryHomeAddress: secondaryResidenceAddress || '',
      avatarUrl: person.icon || '',
      generation,
      parents: person.properties.parentsId || null,
      families: person.properties.familyIds || [],
      profileUrl: person.url || '',
      dateOfBirth: person.properties.dateOfBirth || null,
      dateOfDeath: person.properties.dateOfDeath || null,
      occupation: person.properties.occupation || '',
      jobDescription: person.properties.jobDescription || '',
      branch: person.properties.branch || null,
      inheritedBranch: person.properties.inheritedBranch || null,
      familyBranch: person.properties.familyBranch || null,
      parentBranch: person.properties.parentBranch || null,
      start: person.properties.start || false,
      directDescendant:
        person.properties.directDescendant || !!person.properties.parentsId,
    })
  })

  people.sort((a, b) => {
    // Sort by generation
    if (a.generation < b.generation) {
      return -1
    }
    return 1
  })

  people.forEach(person => {
    //Inherited Branch = if Branch Start is empty then Parent Branch otherwise Branch Start
    //Family Branch = Spouses Inherited Branch
    //Branch = if Inherited Branch is not empty then Inherited Branch otherwise Family Branch
    if (!person.branch) {
      person.inheritedBranch = person.branchStart || person.parentBranch || null
      if (!person.inheritedBranch) {
        const personFamily = families.find(f => person.parents === f.id)
        let parent: ApiResponsePerson | undefined = undefined
        if (personFamily) {
          const parents = people.filter(p =>
            personFamily.partners.includes(p.id),
          )
          if (parents.length) {
            parent = parents.find(p => !!p.branch)
          }
        }

        if (parent) {
          person.inheritedBranch = parent.branch
          person.branch = parent.branch
        }
      }
      let spouse: ApiResponsePerson | null | undefined = null
      const familiesWithPerson = families.filter(family =>
        person.families.includes(family.id),
      )

      if (familiesWithPerson.length > 0) {
        const currentFamilies = familiesWithPerson
        if (currentFamilies.length > 0) {
          const currentFamily = currentFamilies[0]
          const spouseId = currentFamily.partners.find(
            (partnerId: string) => partnerId !== person.id,
          )
          if (spouseId) {
            spouse = people.find(
              (_person: ApiResponsePerson) => _person.id === spouseId,
            )
          }
        }
      }

      person.branch = person.inheritedBranch
      person.familyBranch = person.inheritedBranch
      if (person.parents?.length) {
        person.parentBranch = person.inheritedBranch
      }
      if (spouse) {
        spouse.branch = person.inheritedBranch
        spouse.familyBranch = person.inheritedBranch
      }
      if (!person.familyBranch && spouse?.inheritedBranch) {
        person.familyBranch = spouse?.inheritedBranch
      }
    }
  })

  branches.sort((a, b) => {
    const personA = people.find(person => person.id === a.origin)
    const personB = people.find(person => person.id === b.origin)
    const birthDateA = personA?.dateOfBirth
      ? new Date(personA.dateOfBirth)
      : new Date()
    const birthDateB = personB?.dateOfBirth
      ? new Date(personB.dateOfBirth)
      : new Date()

    if (birthDateA < birthDateB) {
      return -1
    }
    return 1
  })

  return {
    people,
    families,
    branches,
  }
}

export default createTreeData
