import React, { useEffect, useState } from 'react'
import calcTree from 'relatives-tree'
import Connector from './Connector'
import { Node, ExtNode } from 'relatives-tree/lib/types'
import { useFilter } from '../../redux/hooks/useFilter'

const TreeLayout = (props: {
  nodes: Node[]
  rootId: string
  width: number
  height: number
  placeholders?: boolean
  centerView: () => void
  rootNodeLeft: number
  setTransform: (x: number, y: number, scale: number) => void
  renderNode: (node: ExtNode) => React.ReactNode
}) => {
  const {
    nodes,
    rootId,
    placeholders,
    width: _width,
    height: _height,
    centerView,
    renderNode,
    rootNodeLeft,
    setTransform,
  } = props

  const data = calcTree(nodes, {
    rootId,
    placeholders,
  })

  const { filter } = useFilter()

  const [didTransform, setDidTransform] = useState(false)

  const width = _width / 2
  const height = _height / 2

  useEffect(() => {
    if (!didTransform && rootNodeLeft !== 0) {
      setTransform(rootNodeLeft, 0, 1)
      setDidTransform(true)
    }
  })

  useEffect(() => {
    if (filter.branch?.length || filter.generations.length) {
      centerView()
    }
  }, [filter])

  return (
    <div
      style={{
        position: 'relative',
        width: data.canvas.width * width,
        height: data.canvas.height * height,
      }}>
      {data.connectors.map((connector, index) => (
        <Connector
          key={index}
          connector={connector}
          width={width}
          height={height}
        />
      ))}
      {data.nodes.map(renderNode)}
    </div>
  )
}

export default TreeLayout
