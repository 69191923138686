import React, { useEffect, useRef, useState } from 'react'
import Select, {
  components,
  GroupBase,
  OptionProps,
  PropsValue,
} from 'react-select'
import getOrdinalSuffix from '../utils/getOrdinalSuffix'
import { setGenerations } from '../redux/slices/filterSlice'
import { useAppDispatch } from '../redux/hooks'
import { useFilter } from '../redux/hooks/useFilter'

const Option = (
  props: OptionProps<
    { value: number; disabled: boolean; label: JSX.Element },
    true,
    GroupBase<{ value: number; disabled: boolean; label: JSX.Element }>
  >,
) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{ accentColor: '#3B2A2D', marginRight: 15 }}
        />
        {props.label}
      </components.Option>
    </div>
  )
}

const MultiselectCheckbox = (props: MultiselectCheckbox) => {
  const { maxGeneration, filterMenu = false } = props

  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const dispatch = useAppDispatch()

  const { filter } = useFilter()

  const wrapperRef = useRef<HTMLDivElement | null>(null)

  let generations = []
  for (let i = 1; i <= maxGeneration; i++) {
    generations.push(i)
  }

  const customComponents = {
    Option,
    MultiValueContainer: ({
      selectProps,
      data,
    }: {
      selectProps: {
        value: PropsValue<{
          value: number
          disabled: boolean
          label: JSX.Element
        }>
      }
      data: { value: number; disabled: boolean; label: JSX.Element }
    }) => {
      const values = selectProps?.value
      if (values) {
        const LabelComponent = data.label
        return Array.isArray(values) &&
          values[values.length - 1].label === data.label ? (
          LabelComponent
        ) : (
          <>
            {LabelComponent}
            <span
              style={{
                width: 3,
                height: 3,
                marginLeft: 7,
                marginRight: 7,
                backgroundColor: '#3B2A2D',
                borderRadius: '50%',
              }}
            />
          </>
        )
      } else return <></>
    },
  }

  const options = generations.map((generation, index) => {
    const selectedGenerations = filter.generations
    const nextGen = generation + 1
    const prevGen = generation - 1
    let disabled = selectedGenerations.length
      ? (!selectedGenerations.includes(nextGen) &&
          !selectedGenerations.includes(prevGen)) ||
        (selectedGenerations.includes(nextGen) &&
          selectedGenerations.includes(prevGen))
      : false

    if (
      selectedGenerations.length === 1 &&
      selectedGenerations[0] === generation
    ) {
      disabled = false
    }

    return {
      value: generation,
      disabled,
      label: (
        <label
          key={index}
          style={{
            color: '#3B2A2D',
            opacity: disabled ? 0.5 : 1,
            fontWeight: 600,
            fontVariantNumeric: 'lining-nums',
          }}>
          <span>{generation}</span>
          <span style={{ verticalAlign: 'super', fontSize: 12 }}>
            {getOrdinalSuffix(Number(generation))}
          </span>
        </label>
      ),
    }
  })

  const handleClick = (e: MouseEvent) => {
    if (!wrapperRef.current?.contains(e.target as Node)) {
      setMenuIsOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [])

  return (
    <div ref={wrapperRef} style={styles.selectContainer}>
      {!filterMenu && <div style={styles.selectText}>Generation:</div>}
      <div
        style={{
          ...styles.dropdownWrapper,
          borderColor: menuIsOpen ? '#3B2A2D' : '#D8DAE0',
        }}>
        <Select
          placeholder={'All'}
          isClearable={true}
          styles={styles.customSelectStyles}
          onMenuOpen={() => setMenuIsOpen(true)}
          onMenuClose={() => setMenuIsOpen(false)}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isSearchable={false}
          onChange={_options => {
            const values = _options.map(
              (_option: { label: React.ReactNode; value: number }) =>
                _option.value,
            )
            dispatch(setGenerations(values))
          }}
          menuIsOpen={menuIsOpen}
          isMulti={true}
          options={options}
          isOptionDisabled={(option: {
            value: number
            disabled: boolean
            label: JSX.Element
          }) => {
            return option.disabled
          }}
          components={{
            ...customComponents,
          }}
        />
      </div>
    </div>
  )
}

const styles = {
  dropdownWrapper: {
    borderStyle: 'solid',
    borderWidth: 0,
    borderBottomWidth: 1,
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
  } as React.CSSProperties,
  selectText: {
    marginRight: 20,
    marginTop: 10,
    color: '#3B2A2D',
    fontSize: 15,
    fontWeight: 600,
  } as React.CSSProperties,
  customSelectStyles: {
    container: (provided: object) => ({
      ...provided,
      cursor: 'pointer',
      width: 220,
    }),
    placeHolderText: (provided: object) => ({
      ...provided,
      font: 'Ideal Sans',
      fontWeight: 400,
      color: '#3B2A2D',
      fontSize: 18,
    }),
    menu: (provided: object) => ({
      ...provided,
      padding: 0,
      marginTop: 2,
      paddingTop: 10,
      paddingBottom: 5,
      maxHeight: 'none',
      width: 'fit-content',
      borderRadius: 0,
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
    }),
    menuList: (provided: object) => ({
      ...provided,
      padding: 0,
      margin: 0,
      maxHeight: 'none',
    }),
    option: (provided: object) => ({
      ...provided,
      borderBottom: '1px dotted pinked',
      backgroundColor: 'white',
      color: 'black',
      width: 200,
      overflow: 'visible',
      borderRadius: 6,
      padding: 10,
      marginBottom: 5,
      marginLeft: 10,
      marginRight: 10,
      cursor: 'pointer',
      '&:hover': {
        dropShadow: 5.0,
        backgroundColor: '#E8E8E8',
      },
    }),
    multiValueRemove: (provided: object) => ({
      ...provided,
      display: 'none',
    }),
    multiValue: (provided: object) => ({
      ...provided,
      backgroundColor: 'white',
    }),
    indicatorSeparator: (provided: object) => ({
      ...provided,
      display: 'none',
    }),
    indicatorsContainer: (provided: object) => ({
      ...provided,
      borderColor: '1px dotted pink',
    }),
    dropdownIndicator: (provided: object) => ({
      ...provided,
      color: 'black',
    }),
    control: (provided: object, state: { menuIsOpen: boolean }) => ({
      ...provided,
      border: 'none',
      borderRadius: 0,
      borderStyle: 'solid',
      borderWidth: 0,
      borderBottomWidth: 1,
      borderColor: state.menuIsOpen ? '#3B2A2D' : 'blue',
      minWidth: 170,
      boxShadow: 'none',
      paddingBottom: 5,
      cursor: 'pointer',
    }),
    placeholder: (provided: object) => ({
      ...provided,
      color: '#3B2A2D',
      display: 'All',
    }),
  },
}

export default MultiselectCheckbox
