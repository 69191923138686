import React from 'react'
import PeopleSelect from './PeopleSelect'
import MultiselectCheckbox from './MultiselectCheckbox'
import { useWindowSize } from '../hooks/useWindowSize'
import FilterMenu from './FilterMenu'
import { useFilter } from '../redux/hooks/useFilter'
import { useAppDispatch } from '../redux/hooks'
import { setFilterMenuOpen } from '../redux/slices/filterSlice'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as Brand } from '../assets/Brand.svg'

const AppBar = (props: AppBar) => {
  const { data } = props

  const generations = data?.people.map(person => person.generation)
  const maxGeneration =
    generations && generations.length > 0 ? Math.max(...generations) : 0

  const { width } = useWindowSize()

  const { filter } = useFilter()
  const dispatch = useAppDispatch()

  return (
    <>
      <FilterMenu
        data={data}
        maxGeneration={maxGeneration}
        isOpen={filter.filterMenuOpen}
      />
      <div style={styles.container}>
        <div style={styles.brandContainer}>
          <Brand style={styles.logo} alt={'brand'} />
          {width > 1085 && (
            <>
              <span style={styles.leftText}>Barnard Family</span>
              <span style={styles.rightText}>Connect</span>
            </>
          )}
        </div>
        {data && width > 775 && (
          <div style={styles.dropdownContainer}>
            <div style={styles.generationContainer}>
              <MultiselectCheckbox maxGeneration={maxGeneration} />
            </div>
            <div style={styles.branchContainer}>
              <PeopleSelect branches={data.branches} />
            </div>
          </div>
        )}
        {data && width <= 775 && (
          <img
            alt={'menu'}
            src={require('../assets/Menu.png')}
            onClick={() => dispatch(setFilterMenuOpen(!filter.filterMenuOpen))}
            style={{ height: 50, cursor: 'pointer' }}
          />
        )}
      </div>
    </>
  )
}

const styles = {
  container: {
    width: '100%',
    height: 69,
    backgroundColor: 'white',
    borderColor: '#D8DAE0',
    borderStyle: 'solid',
    borderWidth: 0,
    borderBottomWidth: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    paddingLeft: 18,
    paddingRight: 18,
    position: 'fixed',
    zIndex: 10,
  } as React.CSSProperties,
  brandContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    flex: 1,
  } as React.CSSProperties,
  logo: {
    width: 50,
    height: 50,
  } as React.CSSProperties,
  leftText: {
    marginLeft: 10,
    marginRight: 7,
    font: 'Ideal Sans',
    fontWeight: 100,
    fontSize: 30,
    color: '#807476',
  } as React.CSSProperties,
  rightText: {
    font: 'Ideal Sans',
    fontWeight: 500,
    fontSize: 30,
    fontStyle: 'italic',
    color: '#7D998F',
  } as React.CSSProperties,
  dropdownContainer: {
    marginLeft: 15,
    marginTop: -10,
    display: 'flex',
  } as React.CSSProperties,
  branchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 45,
  } as React.CSSProperties,
  branchLabel: {
    marginTop: 15,
    font: 'Ideal Sans',
    fontWeight: 500,
    marginRight: 15,
    fontSize: 15,
  } as React.CSSProperties,
  generationContainer: {} as React.CSSProperties,
}

export default AppBar
