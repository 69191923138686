import React, { useMemo, useState } from 'react'
import PersonNode from '../PersonNode/PersonNode'
import { useFilter } from '../../redux/hooks/useFilter'
import { formatData } from '../../utils/layout'
import { useNavigate } from 'react-router-dom'
import TreeLayout from './TreeLayout'
import { Node, ExtNode } from 'relatives-tree/lib/types'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

const REAL_NODE_HEIGHT = 100
const REAL_NODE_WIDTH = 226
const NODE_HEIGHT = REAL_NODE_HEIGHT * 2
const NODE_WIDTH = REAL_NODE_WIDTH * 2

const Tree = (props: Tree) => {
  const { branches, families, people } = props
  const [dragging, setDragging] = useState(false)
  const [rootNodeLeft, setRootNodeLeft] = useState<number>(0)

  const { filter } = useFilter()
  const navigate = useNavigate()

  const nodes = useMemo(
    () => formatData(people, families, branches, filter),
    [people, branches, families, filter],
  )

  const roots = useMemo(() => {
    let _roots = nodes.filter(n => n.isRoot)
    const spousesToCheck: string[] = []
    const rootsToRemove: string[] = []
    _roots.forEach(root => {
      if (spousesToCheck.includes(root.id)) {
        rootsToRemove.push(root.id)
      } else if (root.spouses.length) {
        spousesToCheck.push(...root.spouses.map(s => s.id))
      }
    })

    _roots = _roots.filter(r => !rootsToRemove.includes(r.id))

    if (!_roots.length) {
      _roots = nodes.filter(
        n => n.id === 'ed23ef97-bb05-4167-9201-f542b122793c',
      )
    }
    return _roots
  }, [nodes])

  return nodes.length ? (
    <div
      onMouseDown={() => setDragging(true)}
      onMouseUp={() => setDragging(false)}
      style={{ paddingTop: 69, cursor: dragging ? 'grabbing' : 'grab' }}>
      <TransformWrapper
        minScale={0.075}
        maxScale={1.5}
        wheel={{
          step: 0.0075,
        }}
        doubleClick={{
          step: 1,
        }}>
        {({ centerView, setTransform }) => (
          <TransformComponent
            contentStyle={{
              flexWrap: 'nowrap',
            }}
            wrapperStyle={{
              maxWidth: '100%',
              maxHeight: 'calc(100vh - 50px)',
              flexWrap: 'nowrap',
            }}>
            {roots.map((root, index) => {
              return (
                <TreeLayout
                  key={index}
                  nodes={nodes as unknown as Node[]}
                  rootId={root.id}
                  width={NODE_WIDTH}
                  height={NODE_HEIGHT}
                  //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setTransform={setTransform}
                  rootNodeLeft={
                    -1 * rootNodeLeft + (window.innerWidth / 2 - 370)
                  }
                  centerView={centerView}
                  renderNode={(node: ExtNode) => {
                    let translateLeft = node.left * (NODE_WIDTH / 2)
                    let translateTop = node.top * (NODE_HEIGHT / 2)
                    translateTop += REAL_NODE_HEIGHT / 2
                    translateLeft += REAL_NODE_WIDTH / 2 - 30

                    if (node.id === 'ed23ef97-bb05-4167-9201-f542b122793c') {
                      setRootNodeLeft(translateLeft)
                    }

                    return (
                      <div
                        style={{
                          position: 'absolute',
                          width: REAL_NODE_WIDTH,
                          height: REAL_NODE_HEIGHT,
                          transform: `translate(${translateLeft}px, ${translateTop}px)`,
                        }}>
                        <PersonNode
                          {...(node as unknown as TreeNode)}
                          branches={branches}
                          onClick={() =>
                            node.id.includes('unknown')
                              ? null
                              : navigate(`/person/${node.id}`)
                          }
                        />
                      </div>
                    )
                  }}
                />
              )
            })}
          </TransformComponent>
        )}
      </TransformWrapper>
    </div>
  ) : null
}

export default Tree
