import React, { useEffect, useRef, useState } from 'react'
import Button from '../components/Button'
import ProfileImage from '../components/ProfileImage'
import NameDateRange from '../components/NameDateRange'
import PersonContactDetails from '../components/PersonContactDetails'
import { DEFAULT_PROFILE_IMAGE_URL } from '../globals'
import { useNavigate } from 'react-router-dom'
import CardPeopleList from './CardPeopleList'
import getDisplayName from '../utils/getDisplayName'

const CardDetailsSectionMobile = (props: CardDetailsSection) => {
  const { person } = props

  const [showFamily, setShowFamily] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (showFamily) {
      ref.current?.scrollTo(0, 0)
    }
  }, [showFamily])

  return (
    <div
      ref={ref}
      style={{
        ...styles.container,
        overflowY: showFamily ? 'hidden' : 'auto',
      }}>
      {showFamily && (
        <div style={styles.listWrapper}>
          <CardPeopleList
            minimized
            goBack={() => setShowFamily(false)}
            people={person.relevantPeople || []}
          />

          <div
            style={{
              position: 'absolute',
              bottom: 20,
              left: '50%',
              transform: 'translateX(-50%)',
            }}>
            <Button
              onClick={() => {
                setShowFamily(false)
              }}
              width={125}
              type={'primary'}
              text={'Back'}
            />
          </div>
        </div>
      )}
      <div>
        <div style={styles.topBar} />
        <div style={styles.header}>
          <div style={styles.imageWrapper}>
            <ProfileImage
              size={110}
              showBorder={true}
              image={person.avatarUrl || DEFAULT_PROFILE_IMAGE_URL}
            />
          </div>
          <NameDateRange
            minimized
            name={
              person.middleName
                ? getDisplayName(person.fullName, person.middleName)
                : person.fullName
            }
            birthDate={person.dateOfBirth?.toLocaleString()}
            deathDate={person.dateOfDeath?.toLocaleString()}
          />
        </div>
        <PersonContactDetails
          skills={person.skills || null}
          email={person.email || null}
          address={person.primaryHomeAddress || null}
          occupation={person.occupation || null}
          phone={person.cellPhone || person.homePhone || null}
          minimized
        />
      </div>
      <div style={styles.buttonWrapper}>
        <Button
          width={125}
          type={'primary'}
          text={'View Profile'}
          onClick={() => {
            window.open(person.profileUrl, '__blank')
          }}
        />
        <div style={{ marginTop: 10 }}>
          <Button
            width={125}
            type={'primary'}
            text={'View Family'}
            onClick={() => {
              setShowFamily(true)
            }}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <Button
            width={125}
            type={'secondary'}
            text={'Close'}
            onClick={() => {
              navigate('/')
            }}
          />
        </div>
      </div>
    </div>
  )
}

const styles = {
  container: {
    paddingLeft: 32,
    paddingRight: 25,
    paddingBottom: 25,
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderWidth: 0,
    borderColor: '#D8DAE0',
    borderTopRightRadius: 7,
    borderBottomRightRadius: 7,
    borderTopLeftRadius: 7,
    borderBottomLeftRadius: 7,
    top: 25,
    width: 'fit-content',
    maxHeight: 'calc(100vh - 100px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
  } as React.CSSProperties,
  listWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#e8e8e8',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#D8DAE0',
    borderTopLeftRadius: 7,
    borderBottomLeftRadius: 7,
    boxShadow: '0px 4px 4px #C6C9CE29',
  } as React.CSSProperties,
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginBottom: 32,
  } as React.CSSProperties,
  imageWrapper: {
    marginTop: -35,
    marginBottom: 15,
  } as React.CSSProperties,
  topBar: {
    width: 'calc(100% + 57px)',
    marginLeft: -32,
    marginRight: -25,
    height: 24,
    backgroundColor: '#94888A',
    marginBottom: 18,
    borderTopRightRadius: 7,
    borderTopLeftRadius: 7,
  } as React.CSSProperties,
  buttonWrapper: {
    marginTop: 25,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  } as React.CSSProperties,
}

export default CardDetailsSectionMobile
