export const formatData = (
  _people: Person[],
  families: Family[],
  branches: Branch[],
  filter: FilterSliceState,
) => {
  let people = _people.filter(
    p => p.fullName.length > 0 && !p.fullName.includes('Pellett'),
  )

  people = people.filter(p => p.families.length > 0 || !!p.parents)

  if (filter.branch?.length) {
    people = people.filter(p => p.branch === filter.branch)
  }

  if (filter.generations.length) {
    people = people.filter(p => filter.generations.includes(p.generation))
  }

  people = people.sort((a, b) => {
    const aBirthDate = new Date(a.dateOfBirth || '')
    const bBirthDate = new Date(b.dateOfBirth || '')
    return aBirthDate < bBirthDate ? -1 : 1
  })

  const nodes: TreeNode[] = people.map(person => {
    const parents: TreeNodeParent[] = []
    const siblings: TreeNodeSibling[] = []
    const spouses: TreeNodeSpouse[] = []
    const children: TreeNodeChild[] = []

    //get parents
    const parentsFamily = families.find(f => f.id === person.parents)

    const minFilterGen = Math.min(...filter.generations)
    let isRoot = false

    if (filter.generations.length) {
      isRoot = person.generation === minFilterGen && person.directDescendant
    } else if (filter.branch?.length) {
      isRoot = person.generation === 3 && person.directDescendant
    }

    parentsFamily?.partners.forEach(partner => {
      parents.push({
        id: partner,
        type: 'blood',
      })
    })

    //get siblings
    parentsFamily?.children?.forEach(child => {
      if (child !== person.id) {
        siblings.push({
          id: child,
          type: 'blood',
        })
      }
    })

    const parentsPeople = people.filter(p =>
      parentsFamily?.partners.includes(p.id),
    )

    const parentsOtherFamilyIds = parentsPeople
      .map(p => p.families.filter(f => f !== parentsFamily?.id))
      .flat()

    const parentsOtherFamilies = families.filter(f =>
      parentsOtherFamilyIds.includes(f.id),
    )

    parentsOtherFamilies.forEach(f => {
      f?.children?.forEach(child => {
        siblings.push({
          id: child,
          type: 'half',
        })
      })
    })

    //get spouses
    let personFamilies = families.filter(f => person.families.includes(f.id))
    const currentFamily = personFamilies.find(f => f.currentRelationship)
    if (currentFamily) {
      personFamilies = [
        currentFamily,
        ...personFamilies.filter(f => f.id !== currentFamily.id),
      ]
    }
    personFamilies.forEach(f => {
      const spouse = f.partners.find(p => p !== person.id)
      if (spouse) {
        spouses.push({
          id: spouse,
          type: f.currentRelationship ? 'married' : 'divorced',
        })
      }

      //get children
      let _children = people.filter(p => f?.children?.includes(p.id))
      _children.forEach(child => {
        children?.push({
          id: child.id,
          type: 'blood',
        })
      })
    })

    return {
      ...person,
      parents: isRoot ? [] : parents,
      isRoot,
      siblings,
      spouses,
      children,
      onClick: () => {
        return
      },
      branches: [],
    }
  })

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const oneSpouseFamilies = families.filter(
    f =>
      f.partners.length === 1 &&
      people.find(p => f.children.includes(p.id) || f.partners.includes(p.id)),
  )
  const unknownSpouses: TreeNode[] = []
  oneSpouseFamilies.forEach((f, index) => {
    unknownSpouses.push({
      id: `unknown-${index}`,
      fullName: 'Unknown',
      dateOfBirth: '',
      dateOfDeath: '',
      directDescendant: false,
      branches: [],
      spouses: [
        {
          id: f.partners[0],
          type: 'divorced',
        },
      ],
      onClick: () => {
        return
      },
      parents: [],
      children: f?.children.map(c => {
        return {
          id: c,
          type: 'blood',
        }
      }),
      siblings: [],
      isRoot: false,
    })
  })

  unknownSpouses.forEach(s => {
    const knownSpouse = nodes.find(n => n.id === s.spouses[0].id)
    if (knownSpouse) {
      knownSpouse.spouses.push({
        id: s.id,
        type: 'divorced',
      })
    }

    const unknownSpouseChildren = nodes.filter(n =>
      s?.children.map(c => c.id).includes(n.id),
    )

    unknownSpouseChildren.forEach(c => {
      c.parents.push({
        id: s.id,
        type: 'blood',
      })
    })
  })

  nodes.push(...unknownSpouses)

  console.log(nodes.length)

  return nodes
}
