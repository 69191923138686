import React from 'react'
import MultiselectCheckbox from './MultiselectCheckbox'
import PeopleSelect from './PeopleSelect'
import { slide as Menu } from 'react-burger-menu'
import { setFilterMenuOpen } from '../redux/slices/filterSlice'
import { useAppDispatch } from '../redux/hooks'
import { useFilter } from '../redux/hooks/useFilter'

type FilterMenu = {
  data: Tree | null
  maxGeneration: number
  isOpen: boolean
}
const FilterMenu = (props: FilterMenu) => {
  const { data, maxGeneration } = props

  const dispatch = useAppDispatch()
  const { filter } = useFilter()

  return data ? (
    <Menu
      width={270}
      styles={styles.menu}
      isOpen={filter.filterMenuOpen}
      onOpen={() => dispatch(setFilterMenuOpen(true))}
      onClose={() => dispatch(setFilterMenuOpen(false))}>
      <img
        alt={'close'}
        src={require('../assets/XIcon.png')}
        onClick={() => dispatch(setFilterMenuOpen(false))}
        style={{
          width: 20,
          height: 20,
          position: 'absolute',
          top: 10,
          right: 10,
          cursor: 'pointer',
        }}
      />
      <div style={styles.wrapper}>
        <div style={styles.headingText}>Generation</div>
        <MultiselectCheckbox maxGeneration={maxGeneration} filterMenu />
      </div>
      <div style={styles.wrapper}>
        <div style={styles.headingText}>Branches</div>
        <PeopleSelect branches={data.branches} filterMenu />
      </div>
    </Menu>
  ) : null
}

const styles = {
  menu: {
    bmMenu: {
      backgroundColor: 'white',
      padding: '25px',
      overflow: 'hidden',
      paddingTop: '50px',
    },
    bmBurgerButton: {
      display: 'none',
    },
  },
  headingText: {
    color: '#3B2A2D',
    fontSize: 15,
    fontWeight: 600,
  },
  wrapper: {
    marginBottom: 50,
  },
}

export default FilterMenu
