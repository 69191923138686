import React, { useState } from 'react'
import Button from '../components/Button'
import ProfileImage from '../components/ProfileImage'
import NameDateRange from '../components/NameDateRange'
import PersonContactDetails from '../components/PersonContactDetails'
import { DEFAULT_PROFILE_IMAGE_URL } from '../globals'
import { useNavigate } from 'react-router-dom'
import CardPeopleList from './CardPeopleList'
import getDisplayName from '../utils/getDisplayName'

const CardDetailsSection = (props: CardDetailsSection) => {
  const { person, minimized } = props

  const [showFamily, setShowFamily] = useState(false)

  const navigate = useNavigate()

  return (
    <div
      style={{
        ...styles.container,
        borderRadius: 7,
        borderBottomLeftRadius: minimized ? 7 : 0,
      }}>
      {!minimized && (
        <div style={styles.listWrapper}>
          <CardPeopleList people={person.relevantPeople || []} />
        </div>
      )}
      {minimized && showFamily && (
        <div style={styles.listWrapperMin}>
          <CardPeopleList
            people={person.relevantPeople || []}
            minimized
            goBack={() => setShowFamily(false)}
          />
          <div
            style={{
              position: 'absolute',
              bottom: 20,
              right: 20,
            }}>
            <Button
              onClick={() => {
                setShowFamily(false)
              }}
              width={125}
              type={'primary'}
              text={'Back'}
            />
          </div>
        </div>
      )}
      <div>
        <div
          style={{ ...styles.topBar, borderTopLeftRadius: minimized ? 6 : 0 }}
        />
        <div style={styles.header}>
          <div style={styles.imageWrapper}>
            <ProfileImage
              size={110}
              showBorder={true}
              image={person.avatarUrl || DEFAULT_PROFILE_IMAGE_URL}
            />
          </div>
          <NameDateRange
            name={
              person.middleName
                ? getDisplayName(person.fullName, person.middleName)
                : person.fullName
            }
            birthDate={person.dateOfBirth?.toLocaleString()}
            deathDate={person.dateOfDeath?.toLocaleString()}
          />
        </div>
        <PersonContactDetails
          skills={person.skills || null}
          email={person.email || null}
          address={person.primaryHomeAddress || null}
          occupation={person.occupation || null}
          phone={person.cellPhone || person.homePhone || null}
        />
      </div>
      <div style={styles.buttonWrapper}>
        <div style={{ marginRight: 10 }}>
          <Button
            width={125}
            type={'secondary'}
            text={'Close'}
            onClick={() => {
              navigate('/')
            }}
          />
        </div>
        {minimized && (
          <div style={{ marginRight: 10 }}>
            <Button
              width={125}
              type={'primary'}
              text={'View Family'}
              onClick={() => {
                setShowFamily(true)
              }}
            />
          </div>
        )}
        <Button
          width={125}
          type={'primary'}
          text={'View Profile'}
          onClick={() => {
            window.open(person.profileUrl, '__blank')
          }}
        />
      </div>
    </div>
  )
}

const styles = {
  container: {
    paddingLeft: 32,
    paddingRight: 25,
    paddingBottom: 25,
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderWidth: 0,
    borderColor: '#D8DAE0',
    width: 560,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 410,
    maxHeight: 485,
    position: 'relative',
  } as React.CSSProperties,
  listWrapper: {
    position: 'absolute',
    top: -1,
    bottom: -1,
    left: -275,
    backgroundColor: '#e8e8e8',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#D8DAE0',
    borderTopLeftRadius: 7,
    borderBottomLeftRadius: 7,
    boxShadow: '0px 4px 4px #C6C9CE29',
  } as React.CSSProperties,
  listWrapperMin: {
    position: 'absolute',
    top: -25,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#e8e8e8',
    borderRadius: 7,
  } as React.CSSProperties,
  header: {
    display: 'flex',
    width: '100%',
    marginBottom: 32,
  } as React.CSSProperties,
  imageWrapper: {
    marginTop: -55,
    marginRight: 20,
  } as React.CSSProperties,
  topBar: {
    width: 'calc(100% + 57px)',
    marginLeft: -32,
    marginRight: -25,
    height: 24,
    backgroundColor: '#94888A',
    marginBottom: 18,
    borderTopRightRadius: 6,
  } as React.CSSProperties,
  buttonWrapper: {
    marginTop: 25,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  } as React.CSSProperties,
}

export default CardDetailsSection
