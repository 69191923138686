import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import modalReducer from './slices/modalSlice'
import filterReducer from './slices/filterSlice'
import { familyTreeApi } from './slices/api/apiSlice'
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const reducers = combineReducers({
  [familyTreeApi.reducerPath]: familyTreeApi.reducer,
  modalSlice: modalReducer,
  filterSlice: filterReducer,
})

const persistConfig = {
  key: 'root',
  storage,
}

const nonPersistedStore = configureStore({ reducer: reducers })
type NonPersistedRootState = ReturnType<typeof nonPersistedStore.getState>

const persistedReducer = persistReducer<NonPersistedRootState>(
  persistConfig,
  reducers,
)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(familyTreeApi.middleware),
})

export const persistor = persistStore(store)

store.getState()

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
