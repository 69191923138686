import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

export const initialState: FilterSliceState = {
  generations: [],
  branch: null,
  filterMenuOpen: false,
}
const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setGenerations: (state, action) => {
      state.generations = action.payload
    },
    setBranch: (state, action) => {
      state.branch = action.payload
    },
    setFilterMenuOpen: (state, action) => {
      state.filterMenuOpen = action.payload
    },
  },
})

export const selectFilter = (state: RootState) => state.filterSlice
export const { setGenerations, setBranch, setFilterMenuOpen } =
  filterSlice.actions
export default filterSlice.reducer
