import React from 'react'
import { Oval } from 'react-loader-spinner'

const Loading = ({ text }: { text?: string }) => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.25)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Oval
        height={80}
        width={80}
        color="#7d998f"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#7d998f"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
      <div style={{ marginTop: 10 }}>{text}</div>
    </div>
  )
}

export default Loading
